import { render, staticRenderFns } from "./CoordinateManagementTemplate.vue?vue&type=template&id=65c673a1&"
import script from "./CoordinateManagementTemplate.vue?vue&type=script&lang=js&"
export * from "./CoordinateManagementTemplate.vue?vue&type=script&lang=js&"
import style0 from "./CoordinateManagementTemplate.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VContainer,VFlex,VIcon,VLayout,VList,VListItem,VListItemAction,VMenu,VRow,VSwitch,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/Users/imaiyuurika/sj_contract_procedure_contractor_front/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65c673a1')) {
      api.createRecord('65c673a1', component.options)
    } else {
      api.reload('65c673a1', component.options)
    }
    module.hot.accept("./CoordinateManagementTemplate.vue?vue&type=template&id=65c673a1&", function () {
      api.rerender('65c673a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/agency/templates/CoordinateManagementTemplate.vue"
export default component.exports