<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <p class="font-weight-black pl-3" style="font-size:32px">
          {{ $t('title.agency.editHighLight') }}
        </p>
      </v-row>
      <ValidationForms ref="forms">
        <v-row class="ml-2 mb-2">
          <v-flex md3>
            <ValidationForm :rules="`required|max:${maxLengthDescription}`">
              <v-text-field
                v-model="coordinateManagement.description"
                type="text"
                hide-details
                :label="$t('label.title')"
                :value="coordinateManagement.description"
              />
            </ValidationForm>
          </v-flex>
        </v-row>
        <v-row class="ml-2">
          <v-flex md6>
            <ValidationForm :rules="`max:${maxLengthMessage}`">
              <v-textarea
                rows="2"
                row-height="20"
                outlined
                clearable
                v-model="coordinateManagement.singlePlanConfirmation"
                :counter="maxLengthMessage"
                :value="coordinateManagement.singlePlanConfirmation"
                :label="$t('label.singlePlanConfirmation')"
              ></v-textarea>
            </ValidationForm>
          </v-flex>
        </v-row>
        <v-row class="ml-2">
          <v-switch
            class="my-0"
            v-model="singlePlanFlg"
            color="next"
            :label="singlePlanFlgToString()"
          ></v-switch>
        </v-row>
        <v-row v-if="!singlePlanFlg" class="ml-2">
          <v-flex md6>
            <ValidationForm :rules="`max:${maxLengthMessage}`">
              <v-textarea
                rows="2"
                row-height="20"
                outlined
                clearable
                v-model="coordinateManagement.multiplePlansConfirmation"
                :counter="maxLengthMessage"
                :value="coordinateManagement.multiplePlansConfirmation"
                :label="$t('label.multiplePlansConfirmation')"
              ></v-textarea>
            </ValidationForm>
          </v-flex>
        </v-row>
        <v-row class="ml-2">
          <div class="text-center my-auto">
            <input
              type="checkbox"
              id="planFlg"
              value="planFlg"
              v-model="coordinateManagement.planFlg"
              @change="planCheck"
            />
            <label class="ml-1" for="planFlg">{{
              $t('checkbox.planFlg')
            }}</label>
          </div>
          <v-flex md1 />
          <div class="text-center my-auto">
            <input
              type="checkbox"
              id="importantFlg"
              value="coordinateManagement.importantFlg"
              v-model="coordinateManagement.importantFlg"
            />
            <label class="ml-1" for="importantFlg">{{
              $t('checkbox.importantFlg')
            }}</label>
          </div>
          <v-flex md1 />
          <div class="text-center my-auto">
            <input
              type="checkbox"
              id="skipFlg"
              value="coordinateManagement.skipFlg"
              v-model="coordinateManagement.skipFlg"
              @change="skipCheck"
            />
            <label class="ml-1" for="skipFlg">{{
              $t('checkbox.skipFlg')
            }}</label>
          </div>
          <template v-if="!clauseFlg">
            <v-flex md1 />
            <div class="text-center my-auto">
              <input
                type="checkbox"
                id="clauseFlg"
                value="coordinateManagement.clauseFlg"
                v-model="coordinateManagement.clauseFlg"
              />
              <label class="ml-1" for="clauseFlg">{{
                $t('checkbox.clauseFlg')
              }}</label>
            </div>
          </template>
        </v-row>
      </ValidationForms>
      <v-row v-if="!coordinateManagement.clauseFlg">
        <v-flex md3>
          <v-btn
            class="text-none float-left my-auto"
            height="100%"
            text
            :dark="modificationItemsMaxLengthValidation"
            :disabled="!modificationItemsMaxLengthValidation"
            @click="addModificationItem()"
          >
            <v-icon color="error">mdi-plus-circle</v-icon>
          </v-btn>
          <ValidationForms ref="modification">
            <ValidationForm
              :rules="`required|max:${maxLengthModificationItem}`"
            >
              <v-text-field
                v-model="modificationItem"
                type="text"
                hide-details
                :label="$t('label.modificationItem')"
                :disabled="!modificationItemsMaxLengthValidation"
              />
            </ValidationForm>
          </ValidationForms>
        </v-flex>
        <v-flex md1 />
        <v-flex md6 class="my-auto">
          <v-menu offset-y right :close-on-content-click="false">
            <template v-slot:activator="{ on: { click } }">
              <v-btn
                class="font-weight-black mr-4"
                id="modificationItemConfirmButton"
                color="next"
                width="30vw"
                max-width="170px"
                style="font-size:20px !important"
                :dark="isExistsModificationItems"
                :disabled="!isExistsModificationItems"
                v-on:click="click"
              >
                {{ $t('button.modificationItemConfirm') }}
              </v-btn>
            </template>
            <v-list
              class="ma-0 pa-0"
              style="border-radius: 0px; display: block;"
              v-for="(item, index) in coordinateManagement.modificationItems"
              :key="item.id"
            >
              <v-list-item style="display: inline-block; min-width: 100%;">
                <v-icon color="error" @click="deleteModificationItem(index)"
                  >mdi-close-circle</v-icon
                >
                <v-icon color="edit" @click="editModificationItem(index)"
                  >edit</v-icon
                >
                <v-list-item-action class="ml-2">
                  {{ item.name }}
                </v-list-item-action>
                <v-list-item-action
                  class="v_list_item_no_margin arrow_right_margin float-right"
                >
                  <v-icon
                    v-show="index !== 0"
                    @click="updateIndex(index, index - 1)"
                  >
                    arrow_upward
                  </v-icon>
                </v-list-item-action>
                <v-list-item-action
                  class="v_list_item_no_margin arrow_right_margin float-right"
                >
                  <v-icon
                    v-show="
                      index !==
                        coordinateManagement.modificationItems.length - 1
                    "
                    @click="updateIndex(index, index + 1)"
                  >
                    arrow_downward
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
      </v-row>

      <v-row class="content">
        <v-col class="cropper-area" style="max-width:550px; min-width:550px;">
          <div class="img-cropper">
            <template v-if="imgSrc">
              <vue-cropper
                ref="cropper"
                :src="imgSrc"
                preview=".preview"
                :zoomable="false"
              />
            </template>
          </div>
        </v-col>
        <v-col class="preview-area">
          <p>{{ $t('label.preview') }}</p>
          <div class="preview border border-dark" />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="page !== 1" style="text-align: center">
          <!-- 前のページ -->
          <v-btn
            class="font-weight-black"
            align="center"
            width="15vw"
            max-width="300px"
            color="next"
            style="font-size:16px"
            dark
            @click="prevPage"
            >{{ $t('button.prevPage') }}</v-btn
          >
        </v-col>
        <v-col v-if="!checkLastPage()" style="text-align: center">
          <!-- 次のページ -->
          <v-btn
            class="font-weight-black"
            align="center"
            width="15vw"
            max-width="300px"
            color="next"
            style="font-size:16px"
            dark
            @click="nextPage"
            >{{ $t('button.nextPage') }}</v-btn
          >
        </v-col>
        <template v-if="editFlg">
          <v-col style="text-align: center">
            <!-- キャンセル -->
            <v-btn
              class="font-weight-black"
              align="center"
              width="15vw"
              max-width="300px"
              color="next"
              style="font-size:16px"
              dark
              @click="cancelEditFunction()"
              >{{ $t('button.cancel') }}</v-btn
            >
          </v-col>
          <v-col style="text-align: center">
            <!-- 編集完了 -->
            <v-btn
              class="font-weight-black"
              align="center"
              width="15vw"
              max-width="300px"
              color="next"
              style="font-size:16px"
              dark
              @click="completeEdit()"
              >{{ completeEditText }}</v-btn
            >
          </v-col>
        </template>
        <template v-else>
          <v-col style="text-align: center">
            <!-- ハイライト追加 -->
            <v-btn
              class="font-weight-black"
              align="center"
              width="15vw"
              max-width="300px"
              color="next"
              style="font-size:16px"
              :dark="highLightMaxLengthValidation"
              :disabled="!highLightMaxLengthValidation"
              @click="createHighLight()"
              >{{ $t('button.createHighLight') }}</v-btn
            >
          </v-col>
          <template v-if="coordinateManagementList.length > 0">
            <v-col style="text-align: center">
              <!-- ハイライト一覧 -->
              <v-btn
                class="font-weight-black"
                align="center"
                width="15vw"
                max-width="300px"
                color="next"
                style="font-size:16px"
                dark
                @click="showHighLightList()"
                >{{ $t('button.highLightList') }}</v-btn
              >
            </v-col>
            <v-col style="text-align: center">
              <!-- 登録 -->
              <v-btn
                class="font-weight-black"
                align="center"
                width="15vw"
                max-width="300px"
                color="next"
                style="font-size:16px"
                dark
                @click="completeConfirm()"
                >{{ $t('button.register') }}</v-btn
              >
            </v-col>
          </template>
        </template>
      </v-row>
      <!-- ハイライト更新完了通知（申込書マスター登録時のみ表示） -->
      <SimpleDialog
        :showDialog="showEditHighLightDialog"
        :title="$t('title.agency.editHighLight')"
        :confirmText="$t('success.highLightUpdated')"
        :negativeButtonTitle="null"
        :positiveButtonTitle="$t('button.close')"
        :onClickNegativeButton="null"
        :onClickPositiveButton="closeEditHighLightDialog"
      />
      <!-- ハイライト削除確認 -->
      <SimpleDialog
        :showDialog="showDeleteHighLightDialog"
        :title="$t('label.deleteHighLightTitle')"
        :confirmText="$t('description.deleteHighLight')"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.delete')"
        :onClickNegativeButton="cancelDelete"
        :onClickPositiveButton="deleteHighLight"
      />

      <!-- プラン項目変更確認 -->
      <SimpleDialog
        :showDialog="showChangePlanDialog"
        :title="$t('title.agency.changePlanConfirm')"
        :confirmText="$t('description.changePlan')"
        :negativeButtonTitle="$t('button.no')"
        :positiveButtonTitle="$t('button.yes')"
        :onClickNegativeButton="notUpdatePlan"
        :onClickPositiveButton="updatePlan"
      />

      <!-- スキップ項目変更確認 -->
      <SimpleDialog
        :showDialog="showChangeSkipDialog"
        :title="$t('title.agency.changeSkipConfirm')"
        :confirmText="$t('description.changeSkip')"
        :negativeButtonTitle="$t('button.no')"
        :positiveButtonTitle="$t('button.yes')"
        :onClickNegativeButton="notUpdateSkip"
        :onClickPositiveButton="updateSkip"
      />

      <!-- 完了確認(汎用) -->
      <SimpleDialog
        :showDialog="showCompleteConfirmDialog"
        :title="dialogTitle"
        :confirmText="completeText"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="completeButtonText"
        :onClickNegativeButton="closeCompleteConfirmDialog"
        :onClickPositiveButton="beforeComplete"
      />

      <!-- ハイライト一覧表示 -->
      <HightLightListDialog
        :showDialog="showHighLightListDialog"
        :onEnd="onEnd"
        :onClickReorder="onClickReorder"
        :onClickPreview="onClickPreview"
        :onClickEdit="onClickEdit"
        :onClickDelete="onClickDelete"
        :closeHighLightListDialog="closeHighLightListDialog"
        :coordinateManagementList="coordinateManagementList"
        :changeCoordinateManagementListData="changeCoordinateManagementListData"
        :cancelReorder="cancelReorder"
        :completeReorder="completeReorder"
        :order="order"
      />

      <!-- プレビュー表示 -->
      <HightLightPreviewDialog
        :showDialog="showHighLightPreview"
        :closePreview="closePreview"
        :previewDescription="previewDescription"
        :previewImage="previewImage"
      />
      <!-- 修正項目編集ダイアログ -->
      <FormsDialog
        :showDialog="showModificationItemEditFormsDialog"
        :title="$t('title.agency.editModificationItem')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelModificationItemEdit"
        :onClickPositiveButton="updateModificationItem"
        :targets="editModificationItemTargets"
      />
      <ErrorDialog ref="errorDialog"></ErrorDialog>
    </v-layout>
  </v-container>
</template>

<script>
import vueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import HightLightListDialog from '@/components/organisms/agency/HightLightListDialog';
import HightLightPreviewDialog from '@/components/organisms/agency/HightLightPreviewDialog';
import { imageScale, MaxLength, MaxCount } from '@/lib/const';

export default {
  name: 'CoordinateManagementTemplate',
  props: {
    images: Array,
    parentPlanPage: Number,
    parentScreenPage: Number || null,
    parentCoordinateManagement: Object,
    parentClauseFlg: Boolean || false,
    temporaryId: Number,
    onComplete: Function,
    onCancel: Function || null,
    type: String,
    completeButtonText: String,
    dialogTitle: String,
    completeText: String,
  },
  components: {
    vueCropper,
    ValidationForms,
    ValidationForm,
    SimpleDialog,
    FormsDialog,
    ErrorDialog,
    HightLightListDialog,
    HightLightPreviewDialog,
  },
  data() {
    return {
      imgSrc: null,
      data: null,
      page: 1,
      defaultCoordinateManagement: {
        xCoordinate: null,
        yCoordinate: null,
        width: null,
        height: null,
        screenPage: null,
        pdfPage: null,
        description: null,
        singlePlanConfirmation: null,
        multiplePlansConfirmation: null,
        planFlg: null,
        importantFlg: null,
        clauseFlg: null,
        skipFlg: null,
        modificationItems: [],
      },
      coordinateManagement: {
        id: null,
        xCoordinate: null,
        yCoordinate: null,
        width: null,
        height: null,
        screenPage: 1,
        pdfPage: null,
        description: null,
        singlePlanConfirmation: null,
        multiplePlansConfirmation: null,
        planFlg: null,
        importantFlg: null,
        skipFlg: null,
        clauseFlg: null,
        image: null,
        modificationItems: [],
      },
      id: this.temporaryId,
      changeCoordinateManagementListData: [],
      planPage: this.parentPlanPage,
      coordinateManagementList: [],
      previewDescription: null,
      previewImage: null,

      backupCoordinateManagement: {},

      showChangePlanDialog: false,
      showChangeSkipDialog: false,
      showHighLightListDialog: false,
      showHighLightPreview: false,
      showDeleteHighLightDialog: false,
      showEditHighLightDialog: false,
      showCompleteConfirmDialog: false,

      deleteHighLightDetail: null,

      editFlg: false,

      maxLengthDescription: MaxLength.HighLightDescription,
      maxLengthMessage: MaxLength.HighLightMessage,
      maxLengthModificationItem: MaxLength.ModificationItem,

      order: false,

      completeEditText: this.$t('button.update'),

      modificationItem: null,
      clauseFlg: false,
      editModificationItemTargets: [
        {
          value: null,
          targetName: 'name',
          type: 'text',
          label: this.$t('label.modificationItem'),
          text: null,
          rule: `required|max:${MaxLength.ModificationItem}`,
          key: 'name',
        },
      ],
      showModificationItemEditFormsDialog: false,
      editModificationItemIndex: null,

      singlePlanFlg: true,
    };
  },
  async mounted() {
    if (this.images && this.images.length > 0) this.setImage(this.page);

    this.$set(this, 'editFlg', this.type === 'create' ? false : true);

    this.$set(this, 'clauseFlg', this.parentClauseFlg);

    if (!this.isCreating) {
      this.$set(this, 'editFlg', true);
      if (this.isAdding) {
        this.$set(this, 'completeEditText', this.$t('button.add'));
      }
    }
  },
  methods: {
    async createHighLight() {
      if (await this.$refs.forms.validate()) {
        const data = this.$refs.cropper.getData();
        this.coordinateManagement.id = this.id++;
        this.coordinateManagement.xCoordinate = Math.ceil(data.x / imageScale);
        this.coordinateManagement.yCoordinate = Math.ceil(data.y / imageScale);
        this.coordinateManagement.width = Math.floor(data.width / imageScale);
        this.coordinateManagement.height = Math.floor(data.height / imageScale);
        this.coordinateManagement.pdfPage = this.page;
        this.coordinateManagement.modificationItems = this.coordinateManagement
          .clauseFlg
          ? null
          : this.coordinateManagement.modificationItems;
        this.coordinateManagement.multiplePlansConfirmation = this.singlePlanFlg
          ? null
          : this.coordinateManagement.multiplePlansConfirmation;

        if (this.coordinateManagement.clauseFlg)
          this.$set(this, 'clauseFlg', true);

        this.coordinateManagement.image = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();

        if (this.coordinateManagement.planFlg) {
          if (this.planPage) {
            this.coordinateManagementList.map(coordinateManagement => {
              if (coordinateManagement.screenPage === this.planPage)
                coordinateManagement.planFlg = false;
              return coordinateManagement;
            });
          }

          this.planPage = parseInt(this.coordinateManagement.screenPage);
        }
        this.coordinateManagementList.push(this.coordinateManagement);

        this.coordinateManagement = JSON.parse(
          JSON.stringify(this.defaultCoordinateManagement)
        );

        this.coordinateManagement.screenPage =
          this.coordinateManagementList.length + 1;
        this.$refs.forms.reset();
        this.$refs.cropper.reset();
      }
    },
    nextPage() {
      ++this.page;
      this.setImage(this.page);
    },
    prevPage() {
      --this.page;
      this.setImage(this.page);
    },
    async setImage(page) {
      this.imgSrc = this.images[page];
      // rebuild cropperjs with the updated source
      if (this.$refs.cropper) await this.$refs.cropper.replace(this.imgSrc);
    },
    planCheck() {
      if (
        this.coordinateManagement.planFlg &&
        this.planPage &&
        this.planPage !== this.coordinateManagement.screenPage
      )
        this.$set(this, 'showChangePlanDialog', true);
    },
    notUpdatePlan() {
      this.$set(this.coordinateManagement, 'planFlg', null);
      this.$set(this, 'showChangePlanDialog', false);
    },
    updatePlan() {
      this.$set(this, 'showChangePlanDialog', false);
    },
    skipCheck() {
      if (this.coordinateManagement.skipFlg)
        this.$set(this, 'showChangeSkipDialog', true);
    },
    notUpdateSkip() {
      this.$set(this.coordinateManagement, 'skipFlg', null);
      this.$set(this, 'showChangeSkipDialog', false);
    },
    updateSkip() {
      this.$set(this, 'showChangeSkipDialog', false);
    },
    checkLastPage() {
      return this.images.length - 1 === this.page;
    },
    showHighLightList() {
      this.$set(this, 'showHighLightListDialog', true);
    },
    closeHighLightListDialog() {
      this.$set(this, 'showHighLightListDialog', false);
    },
    onClickPreview(item) {
      this.$set(this, 'previewDescription', item.description);
      this.$set(this, 'previewImage', item.image);
      this.$set(this, 'showHighLightPreview', true);
    },
    closePreview() {
      this.$set(this, 'showHighLightPreview', false);
      this.$set(this, 'previewDescription', null);
      this.$set(this, 'previewImage', null);
    },
    onClickDelete(item) {
      this.$set(this, 'showDeleteHighLightDialog', true);
      this.$set(this, 'deleteHighLightDetail', item);
    },
    async deleteHighLight() {
      // 配列データから削除
      this.coordinateManagementList = this.coordinateManagementList.filter(
        coordinateManagement =>
          coordinateManagement.id != this.deleteHighLightDetail.id
      );
      await this.pageSort(this.coordinateManagementList);

      if (this.deleteHighLightDetail.planFlg)
        this.planPage = null;

      this.$set(this, 'showDeleteHighLightDialog', false);
    },
    cancelDelete() {
      this.$set(this, 'showDeleteHighLightDialog', false);
    },
    onClickEdit(item) {
      this.backupCoordinateManagement = JSON.parse(
        JSON.stringify(this.coordinateManagement)
      );
      this.backupPage = this.page;

      const backupData = this.$refs.cropper.getData();
      this.backupCoordinateManagement = {
        x: backupData.x * imageScale,
        y: backupData.y * imageScale,
        width: backupData.width * imageScale,
        height: backupData.height * imageScale,
      };

      this.page = item.pdfPage;

      this.lodaer(item);

      this.coordinateManagement = JSON.parse(JSON.stringify(item));
      this.$set(this, 'editFlg', true);
      this.$set(this, 'showHighLightListDialog', false);
    },
    cancelEditFunction() {
      if (this.isCreating) {
        this.cancelEdit();
      } else {
        this.onCancel();
      }
    },
    cancelEdit() {
      this.coordinateManagement = JSON.parse(
        JSON.stringify(this.backupCoordinateManagement)
      );
      this.page = this.backupPage;

      this.lodaer(this.backupCoordinateManagement);
      this.$set(this, 'editFlg', false);
      this.showHighLightList();
    },
    async completeEdit() {
      if (await this.$refs.forms.validate()) {
        // 修正内容と置換
        const data = this.$refs.cropper.getData();
        this.coordinateManagement.xCoordinate = Math.ceil(data.x / imageScale);
        this.coordinateManagement.yCoordinate = Math.ceil(data.y / imageScale);
        this.coordinateManagement.width = Math.floor(data.width / imageScale);
        this.coordinateManagement.height = Math.floor(data.height / imageScale);
        this.coordinateManagement.pdfPage = this.page;
        this.coordinateManagement.multiplePlansConfirmation = this.singlePlanFlg
          ? null
          : this.coordinateManagement.multiplePlansConfirmation;

        // 新規作成以外は処理を完了確認させる
        if (!this.isCreating) return this.completeConfirm();
        this.coordinateManagement.image = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();

        const coordinateManagementList = this.coordinateManagementList.map(
          coordinateManagement => {
            coordinateManagement =
              coordinateManagement.id === this.coordinateManagement.id
                ? JSON.parse(JSON.stringify(this.coordinateManagement))
                : coordinateManagement;
            return coordinateManagement;
          }
        );
        this.$set(this, 'coordinateManagementList', coordinateManagementList);

        // プラン項目チェック時に他画面のチェック解除
        if (this.coordinateManagement.planFlg) {
          if (this.planPage) {
            this.coordinateManagementList.map(coordinateManagement => {
              if (coordinateManagement.screenPage === this.planPage)
                coordinateManagement.planFlg = false;
              return coordinateManagement;
            });
          }

          this.planPage = parseInt(this.coordinateManagement.screenPage);
        } else {
          // プラン項目のチェックが外された場合
          if (this.coordinateManagement.screenPage === this.planPage)
            this.planPage = null;
        }

        this.coordinateManagement = JSON.parse(
          JSON.stringify(this.backupCoordinateManagement)
        );
        this.page = this.backupPage;

        this.lodaer(this.backupCoordinateManagement);
        this.$set(this, 'editFlg', false);
        this.$set(this, 'showEditHighLightDialog', true);
      }
    },
    closeEditHighLightDialog() {
      this.$set(this, 'showEditHighLightDialog', false);
      this.showHighLightList();
    },
    // ソート動作が終わった時（endイベント）
    async onEnd(e) {
      // 配列のINDEX修正
      await this.moveAt(
        this.changeCoordinateManagementListData,
        e.oldIndex,
        e.newIndex
      );
      this.pageSort(this.changeCoordinateManagementListData);
    },
    onClickReorder() {
      this.$set(this, 'order', true);
      this.$set(
        this,
        'changeCoordinateManagementListData',
        JSON.parse(JSON.stringify(this.coordinateManagementList))
      );
    },
    cancelReorder() {
      this.$set(this, 'order', false);
    },
    completeReorder() {
      // TODO:DB更新
      this.$set(
        this,
        'coordinateManagementList',
        JSON.parse(JSON.stringify(this.changeCoordinateManagementListData))
      );
      this.$set(this, 'order', false);
    },
    completeConfirm() {
      this.$set(this, 'showCompleteConfirmDialog', true);
    },
    beforeComplete() {
      this.onComplete(
        this.isCreating
          ? this.coordinateManagementList
          : this.coordinateManagement
      );
      this.$set(this, 'showCompleteConfirmDialog', false);
    },
    closeCompleteConfirmDialog() {
      this.$set(this, 'showCompleteConfirmDialog', false);
    },
    moveAt(array, index, at) {
      if (index === at || index > array.length - 1 || at > array.length - 1) {
        return array;
      }
      const value = array[index];
      const tail = array.slice(index + 1);
      array.splice(index);
      Array.prototype.push.apply(array, tail);
      array.splice(at, 0, value);
      return array;
    },
    pageSort(coordinateManagementList) {
      coordinateManagementList.map((coordinateManagement, index) => {
        coordinateManagement.screenPage = index + 1;
        if (coordinateManagement.planFlg)
          this.planPage = coordinateManagement.screenPage;
        return coordinateManagement;
      });
      // 新規作成時のみ次ページの番号を正規化
      if (this.isCreating)
       this.coordinateManagement.screenPage = coordinateManagementList.length + 1;
    },
    async lodaer(item) {
      await this.setImage(this.page);

      // PDFページが切り替わるまで繰り返す
      const loader = setInterval(function() {
        const result = loadCropper();
        if (result) clearInterval(loader);
      }, 100);

      // ロード完了判定
      const loadCropper = () => {
        let result = false;
        if (this.$refs.cropper.getCroppedCanvas()) {
          const data = {
            x: item.xCoordinate * imageScale,
            y: item.yCoordinate * imageScale,
            width: item.width * imageScale,
            height: item.height * imageScale,
            rotate: 0,
            scaleX: 1,
            scaleY: 1,
          };
          this.$refs.cropper.setData(data);

          result = true;
        }
        return result;
      };
    },
    async addModificationItem() {
      if (await this.$refs.modification.validate()) {
        // 存在確認
        const exist = this.coordinateManagement.modificationItems.filter(
          modificationItem => modificationItem.name === this.modificationItem
        );
        if (exist.length > 0) {
          this.$refs.errorDialog.open(
            this.$t('title.agency.addModificationItemError'),
            this.$t('error.modificationItemExists', {
              modificationItem: this.modificationItem,
            })
          );
          this.$set(this, 'modificationItem', null);
          this.$refs.modification.reset();
          return;
        }

        this.coordinateManagement.modificationItems.push({
          name: this.modificationItem,
          id: null,
        });
        this.$set(this, 'modificationItem', null);
        this.$refs.modification.reset();
      }
    },
    deleteModificationItem(index) {
      // リストより削除
      this.coordinateManagement.modificationItems.splice(index, 1);
    },
    editModificationItem(index) {
      // 修正項目一覧を非表示
      const btn = document.getElementById('modificationItemConfirmButton');
      btn.click();
      this.$set(this, 'editModificationItemIndex', index);
      this.editModificationItemTargets[0].value = this.coordinateManagement.modificationItems[
        index
      ].name;
      this.$set(this, 'showModificationItemEditFormsDialog', true);
    },
    async cancelModificationItemEdit() {
      await this.$set(this, 'showModificationItemEditFormsDialog', false);
      // 修正項目一覧を表示
      const btn = document.getElementById('modificationItemConfirmButton');
      btn.click();
    },
    async updateModificationItem(item) {
      // 存在確認
      this.$set(this, 'showModificationItemEditFormsDialog', false);
      const exist = this.coordinateManagement.modificationItems.filter(
        modificationItem => modificationItem.name === item.name
      );
      if (
        exist.length > 0 &&
        this.coordinateManagement.modificationItems[
          this.editModificationItemIndex
        ].name != item.name
      ) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.editModificationItemError'),
          this.$t('error.modificationItemExists', {
            modificationItem: item.name,
          })
        );
        return;
      }
      this.coordinateManagement.modificationItems[
        this.editModificationItemIndex
      ].name = item.name;
      await this.$set(this, 'showModificationItemEditFormsDialog', false);
      // 修正項目一覧を表示
      const btn = document.getElementById('modificationItemConfirmButton');
      btn.click();
    },
    updateIndex(index, newIndex) {
      // 順番操作の対象
      const item = this.coordinateManagement.modificationItems[index];

      // 元リストより削除
      this.coordinateManagement.modificationItems.splice(index, 1);

      // 新しい順番に、対象を追加
      this.coordinateManagement.modificationItems.splice(newIndex, 0, item);
    },
    singlePlanFlgToString() {
      return this.singlePlanFlg
        ? this.$t('button.singlePlanFlgOn')
        : this.$t('button.singlePlanFlgOff');
    },
  },
  computed: {
    isCreating() {
      return this.type === 'create';
    },
    isAdding() {
      return this.type === 'add';
    },
    highLightMaxLengthValidation() {
      return this.coordinateManagementList.length < MaxCount.HighLight;
    },
    modificationItemsMaxLengthValidation() {
      return (
        !this.coordinateManagement.modificationItems ||
        this.coordinateManagement.modificationItems.length <
        MaxCount.ModificationItems
      );
    },
    isExistsModificationItems() {
      return (
        this.coordinateManagement.modificationItems &&
        this.coordinateManagement.modificationItems.length > 0
      );
    },
  },
  watch: {
    parentCoordinateManagement(newCoordinateManagement) {
      this.$set(this, 'coordinateManagement', newCoordinateManagement);

      this.page = this.coordinateManagement.pdfPage;

      this.singlePlanFlg = this.coordinateManagement.multiplePlansConfirmation
        ? false
        : true;

      this.lodaer(this.coordinateManagement);
    },

    parentPlanPage(newPlanPage) {
      this.$set(this, 'planPage', newPlanPage);
    },

    parentClauseFlg(newClauseFlg) {
      this.$set(this, 'clauseFlg', newClauseFlg);
    },
    images() {
      if (this.isAdding) this.setImage(this.page);
    },
    parentScreenPage(newScreenPage) {
      this.$set(this.coordinateManagement, 'screenPage', newScreenPage);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  width: 100%;
  height: calc(744px * (9 / 16));
  overflow: hidden;
  border: solid 1px;
}

li {
  cursor: pointer;
  padding: 10px;
  border: solid #ddd 1px;
}
.v-menu__content {
  overflow-y: hidden; 
}
</style>
