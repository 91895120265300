var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", wrap: "" } },
        [
          _c("v-row", [
            _c(
              "p",
              {
                staticClass: "font-weight-black pl-3",
                staticStyle: { "font-size": "32px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("title.agency.editHighLight")) +
                    "\n      "
                )
              ]
            )
          ]),
          _c(
            "ValidationForms",
            { ref: "forms" },
            [
              _c(
                "v-row",
                { staticClass: "ml-2 mb-2" },
                [
                  _c(
                    "v-flex",
                    { attrs: { md3: "" } },
                    [
                      _c(
                        "ValidationForm",
                        {
                          attrs: {
                            rules: "required|max:" + _vm.maxLengthDescription
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "text",
                              "hide-details": "",
                              label: _vm.$t("label.title"),
                              value: _vm.coordinateManagement.description
                            },
                            model: {
                              value: _vm.coordinateManagement.description,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.coordinateManagement,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "coordinateManagement.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-flex",
                    { attrs: { md6: "" } },
                    [
                      _c(
                        "ValidationForm",
                        { attrs: { rules: "max:" + _vm.maxLengthMessage } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              rows: "2",
                              "row-height": "20",
                              outlined: "",
                              clearable: "",
                              counter: _vm.maxLengthMessage,
                              value:
                                _vm.coordinateManagement.singlePlanConfirmation,
                              label: _vm.$t("label.singlePlanConfirmation")
                            },
                            model: {
                              value:
                                _vm.coordinateManagement.singlePlanConfirmation,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.coordinateManagement,
                                  "singlePlanConfirmation",
                                  $$v
                                )
                              },
                              expression:
                                "coordinateManagement.singlePlanConfirmation"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-2" },
                [
                  _c("v-switch", {
                    staticClass: "my-0",
                    attrs: {
                      color: "next",
                      label: _vm.singlePlanFlgToString()
                    },
                    model: {
                      value: _vm.singlePlanFlg,
                      callback: function($$v) {
                        _vm.singlePlanFlg = $$v
                      },
                      expression: "singlePlanFlg"
                    }
                  })
                ],
                1
              ),
              !_vm.singlePlanFlg
                ? _c(
                    "v-row",
                    { staticClass: "ml-2" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { md6: "" } },
                        [
                          _c(
                            "ValidationForm",
                            { attrs: { rules: "max:" + _vm.maxLengthMessage } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  rows: "2",
                                  "row-height": "20",
                                  outlined: "",
                                  clearable: "",
                                  counter: _vm.maxLengthMessage,
                                  value:
                                    _vm.coordinateManagement
                                      .multiplePlansConfirmation,
                                  label: _vm.$t(
                                    "label.multiplePlansConfirmation"
                                  )
                                },
                                model: {
                                  value:
                                    _vm.coordinateManagement
                                      .multiplePlansConfirmation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coordinateManagement,
                                      "multiplePlansConfirmation",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "coordinateManagement.multiplePlansConfirmation"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "ml-2" },
                [
                  _c("div", { staticClass: "text-center my-auto" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.coordinateManagement.planFlg,
                          expression: "coordinateManagement.planFlg"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "planFlg",
                        value: "planFlg"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.coordinateManagement.planFlg)
                          ? _vm._i(
                              _vm.coordinateManagement.planFlg,
                              "planFlg"
                            ) > -1
                          : _vm.coordinateManagement.planFlg
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.coordinateManagement.planFlg,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "planFlg",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.coordinateManagement,
                                    "planFlg",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.coordinateManagement,
                                    "planFlg",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.coordinateManagement, "planFlg", $$c)
                            }
                          },
                          _vm.planCheck
                        ]
                      }
                    }),
                    _c(
                      "label",
                      { staticClass: "ml-1", attrs: { for: "planFlg" } },
                      [_vm._v(_vm._s(_vm.$t("checkbox.planFlg")))]
                    )
                  ]),
                  _c("v-flex", { attrs: { md1: "" } }),
                  _c("div", { staticClass: "text-center my-auto" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.coordinateManagement.importantFlg,
                          expression: "coordinateManagement.importantFlg"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "importantFlg",
                        value: "coordinateManagement.importantFlg"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.coordinateManagement.importantFlg
                        )
                          ? _vm._i(
                              _vm.coordinateManagement.importantFlg,
                              "coordinateManagement.importantFlg"
                            ) > -1
                          : _vm.coordinateManagement.importantFlg
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.coordinateManagement.importantFlg,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "coordinateManagement.importantFlg",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.coordinateManagement,
                                  "importantFlg",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.coordinateManagement,
                                  "importantFlg",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.coordinateManagement,
                              "importantFlg",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      { staticClass: "ml-1", attrs: { for: "importantFlg" } },
                      [_vm._v(_vm._s(_vm.$t("checkbox.importantFlg")))]
                    )
                  ]),
                  _c("v-flex", { attrs: { md1: "" } }),
                  _c("div", { staticClass: "text-center my-auto" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.coordinateManagement.skipFlg,
                          expression: "coordinateManagement.skipFlg"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "skipFlg",
                        value: "coordinateManagement.skipFlg"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.coordinateManagement.skipFlg)
                          ? _vm._i(
                              _vm.coordinateManagement.skipFlg,
                              "coordinateManagement.skipFlg"
                            ) > -1
                          : _vm.coordinateManagement.skipFlg
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.coordinateManagement.skipFlg,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "coordinateManagement.skipFlg",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.coordinateManagement,
                                    "skipFlg",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.coordinateManagement,
                                    "skipFlg",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.coordinateManagement, "skipFlg", $$c)
                            }
                          },
                          _vm.skipCheck
                        ]
                      }
                    }),
                    _c(
                      "label",
                      { staticClass: "ml-1", attrs: { for: "skipFlg" } },
                      [_vm._v(_vm._s(_vm.$t("checkbox.skipFlg")))]
                    )
                  ]),
                  !_vm.clauseFlg
                    ? [
                        _c("v-flex", { attrs: { md1: "" } }),
                        _c("div", { staticClass: "text-center my-auto" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.coordinateManagement.clauseFlg,
                                expression: "coordinateManagement.clauseFlg"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "clauseFlg",
                              value: "coordinateManagement.clauseFlg"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.coordinateManagement.clauseFlg
                              )
                                ? _vm._i(
                                    _vm.coordinateManagement.clauseFlg,
                                    "coordinateManagement.clauseFlg"
                                  ) > -1
                                : _vm.coordinateManagement.clauseFlg
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.coordinateManagement.clauseFlg,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "coordinateManagement.clauseFlg",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.coordinateManagement,
                                        "clauseFlg",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.coordinateManagement,
                                        "clauseFlg",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.coordinateManagement,
                                    "clauseFlg",
                                    $$c
                                  )
                                }
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "ml-1",
                              attrs: { for: "clauseFlg" }
                            },
                            [_vm._v(_vm._s(_vm.$t("checkbox.clauseFlg")))]
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          !_vm.coordinateManagement.clauseFlg
            ? _c(
                "v-row",
                [
                  _c(
                    "v-flex",
                    { attrs: { md3: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none float-left my-auto",
                          attrs: {
                            height: "100%",
                            text: "",
                            dark: _vm.modificationItemsMaxLengthValidation,
                            disabled: !_vm.modificationItemsMaxLengthValidation
                          },
                          on: {
                            click: function($event) {
                              return _vm.addModificationItem()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("mdi-plus-circle")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "ValidationForms",
                        { ref: "modification" },
                        [
                          _c(
                            "ValidationForm",
                            {
                              attrs: {
                                rules:
                                  "required|max:" +
                                  _vm.maxLengthModificationItem
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  "hide-details": "",
                                  label: _vm.$t("label.modificationItem"),
                                  disabled: !_vm.modificationItemsMaxLengthValidation
                                },
                                model: {
                                  value: _vm.modificationItem,
                                  callback: function($$v) {
                                    _vm.modificationItem = $$v
                                  },
                                  expression: "modificationItem"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { md1: "" } }),
                  _c(
                    "v-flex",
                    { staticClass: "my-auto", attrs: { md6: "" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            right: "",
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var click = ref.on.click
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "font-weight-black mr-4",
                                        staticStyle: {
                                          "font-size": "20px !important"
                                        },
                                        attrs: {
                                          id: "modificationItemConfirmButton",
                                          color: "next",
                                          width: "30vw",
                                          "max-width": "170px",
                                          dark: _vm.isExistsModificationItems,
                                          disabled: !_vm.isExistsModificationItems
                                        },
                                        on: { click: click }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "button.modificationItemConfirm"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            339640236
                          )
                        },
                        _vm._l(
                          _vm.coordinateManagement.modificationItems,
                          function(item, index) {
                            return _c(
                              "v-list",
                              {
                                key: item.id,
                                staticClass: "ma-0 pa-0",
                                staticStyle: {
                                  "border-radius": "0px",
                                  display: "block"
                                }
                              },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      "min-width": "100%"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "error" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteModificationItem(
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("mdi-close-circle")]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "edit" },
                                        on: {
                                          click: function($event) {
                                            return _vm.editModificationItem(
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("edit")]
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      { staticClass: "ml-2" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.name) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      {
                                        staticClass:
                                          "v_list_item_no_margin arrow_right_margin float-right"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: index !== 0,
                                                expression: "index !== 0"
                                              }
                                            ],
                                            on: {
                                              click: function($event) {
                                                return _vm.updateIndex(
                                                  index,
                                                  index - 1
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  arrow_upward\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      {
                                        staticClass:
                                          "v_list_item_no_margin arrow_right_margin float-right"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  index !==
                                                  _vm.coordinateManagement
                                                    .modificationItems.length -
                                                    1,
                                                expression:
                                                  "\n                    index !==\n                      coordinateManagement.modificationItems.length - 1\n                  "
                                              }
                                            ],
                                            on: {
                                              click: function($event) {
                                                return _vm.updateIndex(
                                                  index,
                                                  index + 1
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  arrow_downward\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "content" },
            [
              _c(
                "v-col",
                {
                  staticClass: "cropper-area",
                  staticStyle: { "max-width": "550px", "min-width": "550px" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "img-cropper" },
                    [
                      _vm.imgSrc
                        ? [
                            _c("vue-cropper", {
                              ref: "cropper",
                              attrs: {
                                src: _vm.imgSrc,
                                preview: ".preview",
                                zoomable: false
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              ),
              _c("v-col", { staticClass: "preview-area" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("label.preview")))]),
                _c("div", { staticClass: "preview border border-dark" })
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm.page !== 1
                ? _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black",
                          staticStyle: { "font-size": "16px" },
                          attrs: {
                            align: "center",
                            width: "15vw",
                            "max-width": "300px",
                            color: "next",
                            dark: ""
                          },
                          on: { click: _vm.prevPage }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.prevPage")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.checkLastPage()
                ? _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black",
                          staticStyle: { "font-size": "16px" },
                          attrs: {
                            align: "center",
                            width: "15vw",
                            "max-width": "300px",
                            color: "next",
                            dark: ""
                          },
                          on: { click: _vm.nextPage }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.nextPage")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editFlg
                ? [
                    _c(
                      "v-col",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-black",
                            staticStyle: { "font-size": "16px" },
                            attrs: {
                              align: "center",
                              width: "15vw",
                              "max-width": "300px",
                              color: "next",
                              dark: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.cancelEditFunction()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-black",
                            staticStyle: { "font-size": "16px" },
                            attrs: {
                              align: "center",
                              width: "15vw",
                              "max-width": "300px",
                              color: "next",
                              dark: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.completeEdit()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.completeEditText))]
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "v-col",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-black",
                            staticStyle: { "font-size": "16px" },
                            attrs: {
                              align: "center",
                              width: "15vw",
                              "max-width": "300px",
                              color: "next",
                              dark: _vm.highLightMaxLengthValidation,
                              disabled: !_vm.highLightMaxLengthValidation
                            },
                            on: {
                              click: function($event) {
                                return _vm.createHighLight()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("button.createHighLight")))]
                        )
                      ],
                      1
                    ),
                    _vm.coordinateManagementList.length > 0
                      ? [
                          _c(
                            "v-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-black",
                                  staticStyle: { "font-size": "16px" },
                                  attrs: {
                                    align: "center",
                                    width: "15vw",
                                    "max-width": "300px",
                                    color: "next",
                                    dark: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showHighLightList()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("button.highLightList")))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-black",
                                  staticStyle: { "font-size": "16px" },
                                  attrs: {
                                    align: "center",
                                    width: "15vw",
                                    "max-width": "300px",
                                    color: "next",
                                    dark: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.completeConfirm()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("button.register")))]
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ]
            ],
            2
          ),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showEditHighLightDialog,
              title: _vm.$t("title.agency.editHighLight"),
              confirmText: _vm.$t("success.highLightUpdated"),
              negativeButtonTitle: null,
              positiveButtonTitle: _vm.$t("button.close"),
              onClickNegativeButton: null,
              onClickPositiveButton: _vm.closeEditHighLightDialog
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showDeleteHighLightDialog,
              title: _vm.$t("label.deleteHighLightTitle"),
              confirmText: _vm.$t("description.deleteHighLight"),
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.delete"),
              onClickNegativeButton: _vm.cancelDelete,
              onClickPositiveButton: _vm.deleteHighLight
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showChangePlanDialog,
              title: _vm.$t("title.agency.changePlanConfirm"),
              confirmText: _vm.$t("description.changePlan"),
              negativeButtonTitle: _vm.$t("button.no"),
              positiveButtonTitle: _vm.$t("button.yes"),
              onClickNegativeButton: _vm.notUpdatePlan,
              onClickPositiveButton: _vm.updatePlan
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showChangeSkipDialog,
              title: _vm.$t("title.agency.changeSkipConfirm"),
              confirmText: _vm.$t("description.changeSkip"),
              negativeButtonTitle: _vm.$t("button.no"),
              positiveButtonTitle: _vm.$t("button.yes"),
              onClickNegativeButton: _vm.notUpdateSkip,
              onClickPositiveButton: _vm.updateSkip
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showCompleteConfirmDialog,
              title: _vm.dialogTitle,
              confirmText: _vm.completeText,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.completeButtonText,
              onClickNegativeButton: _vm.closeCompleteConfirmDialog,
              onClickPositiveButton: _vm.beforeComplete
            }
          }),
          _c("HightLightListDialog", {
            attrs: {
              showDialog: _vm.showHighLightListDialog,
              onEnd: _vm.onEnd,
              onClickReorder: _vm.onClickReorder,
              onClickPreview: _vm.onClickPreview,
              onClickEdit: _vm.onClickEdit,
              onClickDelete: _vm.onClickDelete,
              closeHighLightListDialog: _vm.closeHighLightListDialog,
              coordinateManagementList: _vm.coordinateManagementList,
              changeCoordinateManagementListData:
                _vm.changeCoordinateManagementListData,
              cancelReorder: _vm.cancelReorder,
              completeReorder: _vm.completeReorder,
              order: _vm.order
            }
          }),
          _c("HightLightPreviewDialog", {
            attrs: {
              showDialog: _vm.showHighLightPreview,
              closePreview: _vm.closePreview,
              previewDescription: _vm.previewDescription,
              previewImage: _vm.previewImage
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showModificationItemEditFormsDialog,
              title: _vm.$t("title.agency.editModificationItem"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelModificationItemEdit,
              onClickPositiveButton: _vm.updateModificationItem,
              targets: _vm.editModificationItemTargets
            }
          }),
          _c("ErrorDialog", { ref: "errorDialog" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }